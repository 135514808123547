import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import { NavLink } from "react-router-dom";
import Aservices from "./Aservices";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import res from "../img/bn3256.png";
import help from "../img/Need-help.webp";
import { logRoles } from "@testing-library/react";
import commerce_Development from "../../src/aservecse_img/002.png";
import commerce_Website_Design from "../../src/aservecse_img/003.png";
import Platform_Integration from "../../src/aservecse_img/004.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/htmlcssimage.jpg";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";
// var Web = Web_Development();

function frontend() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Ajiva Infotech - Custom Software Development Services | Web, Mobile, Cloud Solutions"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content="Software Development, Custom Software Solutions, Mobile App Development, Web Development, Cloud Computing, Enterprise Software, Agile Development, Ajiva Infotech, Software Maintenance, Technology Stack"
        />
        <title>
        Ajiva Infotech - Custom Software Development Services | Web, Mobile, Cloud Solutions
        </title>
        <meta
          property="og:title"
          content="Ajiva Infotech - Custom Software Development Services | Web, Mobile, Cloud Solutions"
        />
        <meta
          property="og:description"
          content="Ajiva Infotech offers top-notch custom software development services, including mobile app development, web development, and cloud solutions. Partner with us for scalable, secure, and innovative software tailored to your business needs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ajivainfotech.com" />
        <meta
          property="og:image"
          content="https://ajivainfotech.com/images/og-image.jpg"
        />
        <meta property="og:site_name" content="Ajiva Infotech" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Ajiva Infotech - Custom Software Development Services | Web, Mobile, Cloud Solutions"
        />
        <meta
          name="twitter:description"
          content="Ajiva Infotech offers top-notch custom software development services, including mobile app development, web development, and cloud solutions. Partner with us for scalable, secure, and innovative software tailored to your business needs."
        />
        <meta
          name="twitter:image"
          content="https://ajivainfotech.com/images/twitter-card.jpg"
        />
        <meta name="twitter:site" content="@AjivaInfotech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="format-detection" content="telephone=no" />

        <meta
          name="description"
          content="Ajiva Infotech offers top-notch custom software development services, including mobile app development, web development, and cloud solutions. Partner with us for scalable, secure, and innovative software tailored to your business needs."
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="text-red-700	 ">Software-Development</h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2  lg:text-2xl font-bold text-[#333333]">
                    Software-Development
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Your Trusted Software Development Partner
                </h1>
                <p className="">
                  At Ajiva Infotech, we transform your vision into reality with
                  cutting-edge software solutions tailored to meet your unique
                  business needs. With years of expertise in software
                  development, we are dedicated to delivering robust, scalable,
                  and innovative software applications that drive business
                  success.
                </p>
              </div>
            </div>
            <div className="Our_Services">
              <h1>Why Choose Ajiva Infotech for Software Development?</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Expertise and Experience
                </h1>
                <p className="">
                  With a team of seasoned professionals, Ajiva Infotech brings a
                  wealth of experience in developing custom software solutions
                  across various industries. Our developers are skilled in the
                  latest technologies and methodologies, ensuring your project
                  is in capable hands.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_2} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Tailored Solutions
                </h1>
                <p className="">
                  We understand that every business is unique. That’s why we
                  offer personalized software development services that align
                  with your specific requirements and goals. From initial
                  consultation to final deployment, we work closely with you to
                  create solutions that fit your business perfectly.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Comprehensive Services
                </h1>
                <p className="">
                  Ajiva Infotech offers a wide range of software development
                  services, including.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold  text-[#333333]">
                  <ul class="">
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Customizable Features
                      </h3>
                      <p class="text-gray-600">
                        Build CRM systems with features that align perfectly
                        with your business processes.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Scalability
                      </h3>
                      <p class="text-gray-600">
                        Solutions that grow with your business, accommodating
                        increasing data and user demands.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Integration
                      </h3>
                      <p class="text-gray-600">
                        Seamless integration with existing systems like ERP, BI
                        tools, and more.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-gray-700">
                        User-friendly Interfaces
                      </h3>
                      <p class="text-gray-600">
                        Intuitive interfaces that enhance user adoption and
                        productivity.
                      </p>
                    </li>
                  </ul>
                </h1>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Quality Assurance
                </h1>
                <p className="">
                  Quality is at the heart of everything we do. Our rigorous
                  testing and quality assurance processes ensure that every
                  software product we deliver is reliable, secure, and performs
                  flawlessly.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Agile Methodology
                </h1>
                <p className="">
                  We adopt agile development practices to ensure flexibility,
                  faster delivery, and continuous improvement. This approach
                  allows us to adapt to changes quickly and deliver high-quality
                  software that meets your evolving business needs.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Our Technology Stack
                </h1>
                <p className="">
                  At Ajiva Infotech, we stay ahead of the curve by utilizing the
                  latest technologies and tools in software development. Our
                  expertise includes:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            {/* -======================================================================================================== */}

            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                {/* <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Agile Methodology
                </h1> */}
                <p className="">
                  <h1 className="lg:text-xl font-bold  text-[#333333]">
                    <ul class="">
                      <li class="  rounded-lg flex gap-2 py-2">

                        <h3 class="text-[17px] font-semibold text-[#333333]">
                          Programming Languages :
                        </h3>
                        <p class="text-gray-600">
                          Java, Python, C#, JavaScript, PHP, Ruby, and more.
                        </p>
                      </li>
                      <li class="  rounded-lg flex gap-2 py-2">

                        <h3 class="text-[17px] font-semibold text-[#333333]">
                          Frameworks :
                        </h3>
                        <p class="text-gray-600">
                          React, Vue.js, Django, Spring, Laravel, and others.
                        </p>
                      </li>
                      <li class="  rounded-lg flex gap-2 py-2">

                        <h3 class="text-[17px] font-semibold text-[#333333]">
                          Databases :
                        </h3>
                        <p class="text-gray-600">
                          MySQL, PostgreSQL, MongoDB, Oracle, SQL Server, and
                          more.
                        </p>
                      </li>
                      <li class="  rounded-lg flex gap-2 py-2">

                        <h3 class="text-[17px] font-semibold text-gray-700">
                          DevOps Tools :
                        </h3>
                        <p class="text-gray-600">
                          Docker, Kubernetes, Jenkins, Git, and more.
                        </p>
                      </li>
                      <li class="  rounded-lg flex gap-2 py-2">

                        <h3 class="text-[17px] font-semibold text-gray-700">
                          Cloud Platforms :
                        </h3>
                        <p class="text-gray-600">
                          AWS, Azure, Google Cloud, IBM Cloud, and more.
                        </p>
                      </li>
                    </ul>
                  </h1>
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Our Process
                </h1>
                <p className="">
                  Our software development process is designed to ensure
                  transparency, efficiency, and high-quality outcomes. Here’s a
                  glimpse of how we work:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Requirement Analysis
                </h1>
                <p className="">
                  We begin by understanding your business needs and project
                  requirements in detail. This involves thorough discussions and
                  consultations to gather all necessary information.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Planning and Design
                </h1>
                <p className="">
                  Based on the requirements, we create a detailed project plan
                  and design the architecture of the software. This stage
                  includes creating wireframes, prototypes, and user interfaces.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Development
                </h1>
                <p className="">
                  Our developers start coding the software, adhering to the best
                  practices and industry standards. We maintain regular
                  communication with you to provide updates and gather feedback.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Testing
                </h1>
                <p className="">
                  Rigorous testing is conducted to identify and fix any bugs or
                  issues. We perform various types of testing, including
                  functional, performance, security, and usability testing.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Deployment
                </h1>
                <p className="">
                  Once the software passes all testing phases, we deploy it to
                  the production environment. Our team ensures a smooth and
                  seamless deployment process.{" "}
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Maintenance and Support:
                </h1>
                <p className="">
                  Post-deployment, we provide continuous maintenance and support
                  to ensure the software remains up-to-date and functions
                  optimally.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Aservices />
      </div>
    </>
  );
}
export default frontend;
