import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import contact_bg from "../img/background_img/Contact-Us.webp";
import help from "../img/Need-help.webp";
import { NavLink } from "react-router-dom";
import Aservices from "./Aservices";
import res from "../img/bn3256.png";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import Expertisecommerce from "../../src/aservecse_img/001.png";
import commerce_Development from "../../src/aservecse_img/002.png";
import commerce_Website_Design from "../../src/aservecse_img/003.png";
import Platform_Integration from "../../src/aservecse_img/004.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/ecom_pg_lfimg_1.png";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";
import Bgimageecinnerce from "../img/banner/bgimageecinnerce.png";
import CustomCommerce from "../img/new/CustomECommerce.png";
import CustomCommerce2 from "../img/new/ProfessionalEccomerce.png";
import CustomCommerce3 from "../img/new/EccomerceAffordable.png";
import ShopifyImage from "../img/new/ShopifyImage.png";
import FormSection from "../components/servicepageheadersection.js";

const EcommerceService = () => {
  <script>
    let hrs = document.querySelector("#hrs"); let sec =
    document.querySelector("#sec"); let min = document.querySelector("#min");
  </script>;
  const countries = [
    { name: "India", dialCode: "+91", code: "IN" },
    { name: "USA", dialCode: "+1", code: "US" },
    { name: "UK", dialCode: "+44", code: "GB" },
  ];

  // Initialize the state using the first country
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  // Handle country selection change
  const handleCountryChange = (event) => {
    const selected = countries.find(
      (country) => country.name === event.target.value
    );
    setSelectedCountry(selected);
  };
  const [isVerified, setIsVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setIsVerified(true);
  };
  const cards = [
    {
      id: 1,
      image: CustomCommerce, // Correct way to pass the image
      title: "Custom E-Commerce Website Development",
      description:
        "Best solutions to meet your specific business requirements. Our custom e-commerce websites are designed to provide seamless user experiences and drive sales.",
    },
    {
      id: 2,
      image: CustomCommerce2, // Correct way to pass the image
      title: "Professional E-Commerce Developers",
      description:
        "Our team comprises skilled and experienced developers who are proficient in the latest e-commerce platforms and technologies. We ensure your website is robust, scalable, and secure.",
    },
    {
      id: 3,
      image: CustomCommerce3, // Correct way to pass the image
      title: "Affordable E-Commerce Website Development",
      description:
        "High-quality e-commerce solutions that fit your budget. We believe that great online stores shouldn't come with a hefty price tag.",
    },
    {
      id: 4,
      image: CustomCommerce3, // Replace with the actual import or path to your image
      title: "Cost-Effective E-Commerce Website Development",
      description:
        "Maximize Your ROI With Our Cost-Effective Development Services. We Deliver Exceptional Value Without Compromising On Quality.",
    },
    {
      id: 5,
      image: CustomCommerce2, // Replace with the actual import or path to your image
      title: "E-Commerce Platforms And Technologies We Use",
      description:
        "We Work With A Variety Of E-Commerce Platforms And Technologies To Ensure We Can Offer The Best Solution For Your Business. Our Expertise Includes But Is Not Limited To:",
    },
    {
      id: 6,
      image: ShopifyImage, // Replace with the actual import or path to your image
      title: "Shopify",
      description:
        "Known For Its Ease Of Use And Robust Features, Shopify Is A Popular Choice For Businesses Of All Sizes.",
    },
    {
      id: 7,
      image: CustomCommerce3, // Replace with the actual import or path to your image
      title: "WooCommerce",
      description:
        "A Flexible, Open-Source E-Commerce Plugin For WordPress, Perfect For Businesses Looking For Customization.",
    },
    {
      id: 8,
      image: ShopifyImage, // Replace with the actual import or path to your image
      title: "BigCommerce",
      description:
        "Ideal For Businesses That Need Advanced SEO And Multi-Channel Selling Capabilities.",
    },
    {
      id: 9,
      image: CustomCommerce2, // Replace with the actual import or path to your image
      title: "PrestaShop",
      description:
        "An Open-Source Platform That Offers Flexibility And A Wide Range Of Features To Support Your Growing Business.",
    },
  ];
  const services = [
    "Ecommerce Application Development",
    "Ecommerce Cart Development",
    "Shopping Cart Development",
    "Web Development And Customization",
    "Custom Ecommerce Website Design",
    "Maintenance And Support",
    "Payment Gateway Integration",
    "Plugin And Module Development",
    "Responsive Shopping Website",
  ];
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Affordable Custom E-Commerce Website Development Services | Professional E-Commerce Developers"
        />
        <title>
          Expert E-commerce Development Solutions for Your Online Success |
          Ajiva Infotech
        </title>

        <meta name="robots" content="index, follow"></meta>
        <meta content="Discover AjivaInfotech, a leading technology partner offering end-to-end software development and testing services. Transform  digital presence today."></meta>
        <meta
          name="description"
          content=" Boost your online sales with our affordable and custom E-Commerce website development services. Professional E-Commerce developers specialize in cost-effective, high-quality E-Commerce websites using the latest platforms and technologies "
        />
        <meta
          name="keywords"
          content="E-Commerce website design and development, Custom E-Commerce website development, Professional E-Commerce developers, Affordable E-Commerce website development, Cost-effective E-Commerce website development"
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className=" container m-auto flex justify-between Ecommerce-bg-image items-center">
            <div className=" building-style">
              <h1 style={{ color: "#EE1021" }}>Building</h1>
              <h2>Beautiful Ecommerce Websites that Drive</h2>
              <h2>Results and Inspire Engagement</h2>
              <p>
                From concept to launch, we'll handle your website development
                with precision and expertise
              </p>
              <div className="flex company-details gap-8 mt-12">
                <div className="flex items-center gap-2">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.1625 7.77409C28.7107 6.30809 26.9816 5.1457 25.0761 4.35466C23.1705 3.56362 21.1266 3.15975 19.0633 3.16659C10.4183 3.16659 3.37252 10.2124 3.37252 18.8574C3.37252 21.6283 4.10085 24.3199 5.46252 26.6949L3.24585 34.8333L11.5583 32.6483C13.8542 33.8991 16.435 34.5641 19.0633 34.5641C27.7083 34.5641 34.7542 27.5183 34.7542 18.8733C34.7542 14.6774 33.1234 10.7349 30.1625 7.77409ZM19.0633 31.9041C16.72 31.9041 14.4242 31.2708 12.4134 30.0833L11.9383 29.7983L6.99835 31.0966L8.31252 26.2833L7.99585 25.7924C6.69363 23.7136 6.00231 21.3104 6.00085 18.8574C6.00085 11.6691 11.8592 5.81076 19.0475 5.81076C22.5308 5.81076 25.8083 7.17242 28.2625 9.64242C29.4779 10.8519 30.4411 12.2906 31.0961 13.8752C31.7511 15.4598 32.085 17.1586 32.0784 18.8733C32.11 26.0616 26.2517 31.9041 19.0633 31.9041ZM26.22 22.1508C25.8242 21.9608 23.8925 21.0108 23.5442 20.8683C23.18 20.7416 22.9267 20.6783 22.6575 21.0583C22.3883 21.4541 21.6442 22.3408 21.4225 22.5941C21.2008 22.8633 20.9634 22.8949 20.5675 22.6891C20.1717 22.4991 18.905 22.0716 17.4167 20.7416C16.245 19.6966 15.4692 18.4141 15.2317 18.0183C15.01 17.6224 15.2 17.4166 15.4058 17.2108C15.58 17.0366 15.8017 16.7516 15.9917 16.5299C16.1817 16.3083 16.2608 16.1341 16.3875 15.8808C16.5142 15.6116 16.4508 15.3899 16.3558 15.1999C16.2608 15.0099 15.4692 13.0783 15.1525 12.2866C14.8358 11.5266 14.5034 11.6216 14.2659 11.6058H13.5058C13.2367 11.6058 12.825 11.7008 12.4608 12.0966C12.1125 12.4924 11.0992 13.4424 11.0992 15.3741C11.0992 17.3058 12.5084 19.1741 12.6983 19.4274C12.8884 19.6966 15.4692 23.6549 19.3958 25.3491C20.33 25.7608 21.0583 25.9983 21.6283 26.1724C22.5625 26.4733 23.4175 26.4258 24.0984 26.3308C24.8584 26.2199 26.4258 25.3808 26.7425 24.4624C27.075 23.5441 27.075 22.7683 26.9642 22.5941C26.8534 22.4199 26.6158 22.3408 26.22 22.1508Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <h1>+91-86999-81982</h1>
                </div>

                <div className="flex items-center gap-2">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33334 31.6666C5.46251 31.6666 4.71728 31.3568 4.09767 30.7372C3.47806 30.1176 3.16773 29.3718 3.16667 28.4999V9.49992C3.16667 8.62909 3.47701 7.88386 4.09767 7.26425C4.71834 6.64464 5.46356 6.33431 6.33334 6.33325H31.6667C32.5375 6.33325 33.2833 6.64358 33.9039 7.26425C34.5246 7.88492 34.8344 8.63014 34.8333 9.49992V28.4999C34.8333 29.3708 34.5235 30.1165 33.9039 30.7372C33.2843 31.3578 32.5386 31.6676 31.6667 31.6666H6.33334ZM19 20.5833L6.33334 12.6666V28.4999H31.6667V12.6666L19 20.5833ZM19 17.4166L31.6667 9.49992H6.33334L19 17.4166ZM6.33334 12.6666V9.49992V28.4999V12.6666Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <h1> info@ajivainfotech.com</h1>
                </div>

                <div className="flex items-center gap-2">
                  {" "}
                  <span class="phone_content p-">
                    <i class="fa-solid fa-phone-volume text-white text-xl"></i>
                  </span>
                  <h1>0172-4084189</h1>
                </div>
              </div>
            </div>
            <div className="building-form-style">
              <div className="Consultation-style">
                {" "}
                <h1 className="mt-2">Get Consultation now!</h1>
                <h2 className="mt-1">
                  Let’s Discuss to Build Something Great Together!
                </h2>
                <FormSection />
              </div>
            </div>
          </div>

          <section className="bg-white py-12 px-4 container m-auto responcivemedia">
            <div className=" flex flex-col md:flex-row  gap-14">
              <div className="relative w-full md:w-1/2">
                <img
                  src={Bgimageecinnerce}
                  alt="Team Meeting"
                  className=" w-full h-auto"
                />
              </div>

              {/* Right Column: Text Content */}
              <div className="w-full md:w-1/2 commerce-website">
                <h2 className="font-bold  mb-6 pr-12  mr-12">
                  Transform Your Online Business With Our E-Commerce Website
                  Development Services
                </h2>
                <p className=" mb-6 leading-relaxed  ">
                  Are you looking to take your online business to the next
                  level? At AIJIVA INFOTECH, our expert team specializes in
                  e-commerce website design and development, providing custom
                  solutions that cater to your unique business needs. With a
                  focus on affordability and quality, we offer professional
                  e-commerce development services to help you succeed in the
                  digital marketplace.
                </p>
                <ul className="space-y-3">
                  {services.map((service, index) => (
                    <li key={index} className="flex items-center">
                      <span className="text-red-600 mr-2">➤</span>
                      {service}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
          <section className="container m-auto">
            <div className="ecommerce-heading py-12 text-center">
              <h1 className="">Our E-Commerce</h1>
              <h2 className="">Website Development Services</h2>
            </div>
          </section>
          <section className="bg-gray-100 py-12 px-4">
            <div className="container m-auto">
              {" "}
              <div className=" mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
                {cards.map((card) => (
                  <div
                    key={card.id}
                    className="bg-white rounded-lg shadow-lg overflow-hidden text-center p-6"
                  >
                    <img
                      src={card.image}
                      alt={card.title}
                      className="w-full  object-cover mb-4 rounded-md"
                    />
                    <h3 className=" font-bold text-gray-800 mb-4">
                      {card.title}
                    </h3>
                    <p className="text-gray-600 mb-6 text-sm leading-relaxed">
                      {card.description}
                    </p>
                    <div className="details-card">
                      {" "}
                      <button class="explore">
                        <span class="icon-right after"></span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </section>
        {/* =================new card================================================= */}

        {/* ===================section ksjdgksvjdvd====================================================== */}

        {/* <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Transform Your Online Business with Our E-Commerce Website
                  Development Services
                </h1>
                <p className="">
                  Are you looking to take your online business to the next
                  level? At <span>AJIVA INFOTECH</span>, Our expert team
                  specializes in E-Commerce website design and development,
                  providing custom solutions that cater to your unique business
                  needs. With a focus on affordability and quality, we offer
                  professional E-Commerce development services to help you
                  succeed in the digital marketplace.
                </p>
              </div>
            </div>
            <div className="Integration-Needs">
              <h1>Our E-Commerce Website Development Services</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer sectio-positon-change bg-[]">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Custom E-Commerce Website Development
                </h1>
                <p className="">
                  Best solutions to meet your specific business requirements.
                  Our custom E-Commerce websites are designed to provide
                  seamless user experiences and drive sales.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_2} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Professional E-Commerce Developers
                </h1>
                <p className="">
                  Our team comprises skilled and experienced developers who are
                  proficient in the latest E-Commerce platforms and
                  technologies. We ensure your website is robust, scalable, and
                  secure.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]   sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Affordable E-Commerce Website Development
                </h1>
                <p className="">
                  High-quality E-Commerce solutions that fit your budget. We
                  believe that great online stores shouldn’t come with a hefty
                  price tag.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]  ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Cost-effective E-Commerce Website Development
                </h1>
                <p className="">
                  Maximize your ROI with our cost-effective development
                  services. We deliver exceptional value without compromising on
                  quality.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  E-Commerce Platforms and Technologies We Use
                </h1>
                <p className="">
                  We work with a variety of E-Commerce platforms and
                  technologies to ensure we can offer the best solution for your
                  business. Our expertise includes but is not limited to:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2   ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Eccomerce_SCO} />
                </div>
              </div>
              <div className="lg:w-1/2   Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Shopify
                </h1>
                <p className="">
                  Known for its ease of use and robust features, Shopify is a
                  popular choice for businesses of all sizes.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  WooCommerce
                </h1>
                <p className="">
                  A flexible, open-source E-Commerce plugin for WordPress,
                  perfect for businesses looking for customization.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Magento
                </h1>
                <p className="">
                  A powerful platform for businesses looking to scale, offering
                  extensive features and customization options.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  WooCommerce
                </h1>
                <p className="">
                  A flexible, open-source E-Commerce plugin for WordPress,
                  perfect for businesses looking for customization.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  BigCommerce
                </h1>
                <p className="">
                  Ideal for businesses that need advanced SEO and multi-channel
                  selling capabilities.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  PrestaShop
                </h1>
                <p className="">
                  An open-source platform that offers flexibility and a wide
                  range of features to support your growing business.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="Integration-Needs">
              <h1>Our Development Process</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Discovery and Planning
                </h1>
                <p className="">
                  We begin with a thorough understanding of your business needs
                  and goals. This phase involves market research, competitor
                  analysis, and defining project requirements.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Design and Prototyping
                </h1>
                <p className="">
                  Our designers create intuitive and visually appealing
                  mock-up’s that reflect your brand identity. We focus on user
                  experience to ensure your customers have a smooth shopping
                  journey.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Development
                </h1>
                <p className="">
                  Using the latest technologies and best practices, our
                  developers bring your website to life. We build responsive,
                  high-performance E-Commerce websites that are optimized for
                  all devices.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Testing and Quality Assurance
                </h1>
                <p className="">
                  Rigorous testing is conducted to ensure your website is
                  bug-free and performs flawlessly. We test for functionality,
                  compatibility, security, and performance.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Launch and Deployment
                </h1>
                <p className="">
                  After successful testing, we launch your website. Our team
                  handles all aspects of deployment to ensure a smooth
                  transition.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Maintenance and Support
                </h1>
                <p className="">
                  Post-launch, we provide ongoing support and maintenance to
                  keep your website up-to-date and running smoothly. We offer
                  regular updates, security checks, and performance
                  optimization.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="Integration-Needs">
              <h1>Latest Trends in E-Commerce Development</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Mobile Commerce
                </h1>
                <p className="">
                  With a significant increase in mobile shopping, we prioritize
                  mobile-first design to enhance the user experience on all
                  devices.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  AI and Personalization
                </h1>
                <p className="">
                  Implementing AI-driven features for personalized shopping
                  experiences, such as product recommendations and customer
                  support chatbots.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Voice Commerce
                </h1>
                <p className="">
                  Integrating voice search capabilities to cater to the growing
                  number of voice-activated searches.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Social Commerce
                </h1>
                <p className="">
                  Leveraging social media platforms for direct selling and
                  improving customer engagement.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Augmented Reality (AR)
                </h1>
                <p className="">
                  Enhancing product visualization with AR, allowing customers to
                  experience products virtually before purchasing.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Get Started Today
                </h1>
                <p className="">
                  Ready to elevate your online business with a custom E-Commerce
                  website? Contact us today to discuss your project
                  requirements. Our team of professional E-Commerce developers
                  is here to provide you with cost-effective, high-quality
                  solutions that drive results.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Aservices />
      </div>
    </>
  );
};
export default EcommerceService;
