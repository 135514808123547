import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Queryform = () => {
  const countries = [
    { name: "India", dialCode: "+91", code: "IN" },
    { name: "USA", dialCode: "+1", code: "US" },
    { name: "UK", dialCode: "+44", code: "GB" },
  ];
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [isVerified, setIsVerified] = useState(false);

  const handleCountryChange = (event) => {
    const selected = countries.find(
      (country) => country.name === event.target.value
    );
    setSelectedCountry(selected);
  };

  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setIsVerified(true);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const formData = new FormData(event.target);
    const payload = {
      fullname: formData.get("fullname"),
      email: formData.get("email"),
      phone: `${selectedCountry.dialCode} ${formData.get("phone")}`,
      message: formData.get("message"),
    };

    console.log("Form Data:", payload);

    try {
      const url = "/api/v1/contactus/";
      const headers = { "Content-Type": "application/json" };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (response.status === 201) {
        console.log("Contact created successfully!");
        event.target.reset(); // Reset the form
      } else {
        console.error("Failed to create contact.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <form
      id="contactid"
      className="Ecommerce-form-style mt-4"
      onSubmit={handleFormSubmit}
    >
      <div className="w-full mb-2 group">
        <input
          className="w-full p-2"
          type="text"
          name="fullname"
          placeholder="Full Name*"
          required
        />
      </div>
      <div className="w-full mb-2 group">
        <input
          className="w-full p-2"
          type="email"
          name="email"
          placeholder="Email*"
          required
        />
      </div>
      <div className="w-full mb-2 group">
        <div className="flex items-center gap-4">
          <div className="w-1/5 bg-white border-style selectedCountry-style">
            <select
              value={selectedCountry.name}
              onChange={handleCountryChange}
              className="appearance-none bg-transparent focus:outline-none p-2 w-full"
            >
              {countries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="w-4/5 bg-white border-style">
            <input
              type="tel"
              name="phone"
              placeholder={selectedCountry.dialCode}
              className="flex-grow p-2 bg-transparent focus:outline-none w-full"
              required
            />
          </div>
        </div>
      </div>
      <div className="w-full mb-2 group">
        <textarea
          className="w-full p-2"
          name="message"
          placeholder="Message*"
          required
          rows="4"
        />
      </div>
      <div
        className="w-full mb-2 group ReCAPTCHA-sectoin"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ReCAPTCHA
          sitekey="6Ldwk5cqAAAAAMnFnCLApuNl8BZtH56BFEu4CDU1"
          onChange={handleCaptchaChange}
        />
      </div>
      <div className="w-full hire-developer">
        <button
          type="submit"
          className="text-sm w-full bg-white sm:w-auto px-5 py-4 text-center cursor-pointer"
          disabled={!isVerified} // Disable button until captcha is verified
        >
          Get Started
        </button>
      </div>
    </form>
  );
};

export default Queryform;
