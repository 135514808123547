import React from "react";
const Terms_Conditions = () => {
  return (
    <>
      <div className="py-10">
        <div className="container m-auto">
          <div className="w-[90%] m-auto">
            <div className="flex">
              <div className="flex w-[80%] m-auto ">
                <div className="text-[#515978] committed_to_ensuring">
                  <h1 className="font-bold text-[25px] p-3 ">Privacy Policy</h1>
                  <h1 className="border-2	w-[30px]  border-[#213554] ml-3"></h1>
                  <p className="font-bold p-3">Last updated on Oct 20th 2023</p>
                  <div className="">
                    <div>
                      <p className="p-3 text-[#515978] text-[14px]">
                        This privacy policy sets out how
                        <span className="text-[15px]">
                          AJIVAINFOTECH PRIVATE LIMITED
                        </span>{" "}
                        uses and protects any information that you give
                        <span className="text-[15px]">
                          AJIVAINFOTECH PRIVATE LIMITED
                        </span>{" "}
                        when you use this website.
                      </p>
                      <p className="p-3 text-[#515978] text-[14px]">
                        <span className="text-[15px]">
                          AJIVAINFOTECH PRIVATE LIMITED''
                        </span>
                        is committed to ensuring that your privacy is protected.
                        Should we ask you to provide certain information by
                        which you can be identified when using this website, and
                        then you can be assured that it will only be used in
                        accordance with this privacy statement.
                      </p>
                      <p className="p-3 text-[14px] font-normal text-[#515978]">
                        <span className="text-[15px]">
                          AJIVAINFOTECH PRIVATE LIMITED
                        </span>
                        may change this policy from time to time by updating
                        this page. You should check this page from time to time
                        to ensure that you are happy with any changes.
                      </p>
                    </div>
                    <div>
                      <h1 className="font-semibold p-3">
                        We may collect the following information:
                      </h1>
                      <ul className="pl-5 text-[#757a91]">
                        <li className="px-3 py-1">Name and job title</li>
                        <li className="px-3 py-1">
                          Contact information including email address
                        </li>
                        <li className="px-3 py-1">
                          Demographic information such as postcode, preferences
                          and interests
                        </li>
                        <li className="px-3 py-1">
                          Other information relevant to customer surveys and/or
                          offers
                        </li>
                      </ul>
                      <h1 className="font-semibold p-3">
                        What we do with the information we gather
                      </h1>
                      <p className="px-3 py-1 text-[15px]">
                        We require this information to understand your needs and
                        provide you with a better service, and in particular for
                        the following reasons:
                      </p>
                      <ul className="pl-5 text-[#757a91] text-[15px]">
                        <li className="p-3">Internal record keeping.</li>
                        <li className="p-3">
                          We may use the information to improve our products and
                          services.
                        </li>
                        <li className="p-3">
                          We may periodically send promotional emails about new
                          products, special offers or other information which we
                          think you may find interesting using the email address
                          which you have provided.
                        </li>
                        <li className="p-3">
                          From time to time, we may also use your information to
                          contact you for market research purposes. We may
                          contact you by email, phone, fax or mail. We may use
                          the information to customise the website according to
                          your interests.
                        </li>
                      </ul>
                    </div>
                    <p className="py-5 text-[#757a91] text-[15px] px-3">
                      We are committed to ensuring that your information is
                      secure. In order to prevent unauthorised access or
                      disclosure we have put in suitable measures.
                    </p>
                    <h1 className="font-semibold py-3 px-3 ">
                      How we use cookies
                    </h1>
                    <p className="text-[#757a91] text-[15px] py-3 px-3">
                      A cookie is a small file which asks permission to be
                      placed on your computer's hard drive. Once you agree, the
                      file is added and the cookie helps analyses web traffic or
                      lets you know when you visit a particular site. Cookies
                      allow web applications to respond to you as an individual.
                      The web application can tailor its operations to your
                      needs, likes and dislikes by gathering and remembering
                      information about your preferences.
                    </p>
                    <p className="text-[#757a91] text-[15px] py-3 px-3">
                      We use traffic log cookies to identify which pages are
                      being used. This helps us analyses data about webpage
                      traffic and improve our website in order to tailor it to
                      customer needs. We only use this information for
                      statistical analysis purposes and then the data is removed
                      from the system.
                    </p>
                    <p className="text-[#757a91] text-[15px] py-3 px-3">
                      Overall, cookies help us provide you with a better
                      website, by enabling us to monitor which pages you find
                      useful and which you do not. A cookie in no way gives us
                      access to your computer or any information about you,
                      other than the data you choose to share with us.
                    </p>
                    <p className="text-[#757a91] text-[15px] py-3 px-3">
                      You can choose to accept or decline cookies. Most web
                      browsers automatically accept cookies, but you can usually
                      modify your browser setting to decline cookies if you
                      prefer. This may prevent you from taking full advantage of
                      the website.
                    </p>
                    <h1 className="font-semibold px-3 ">
                      Controlling your personal information
                    </h1>
                    <p className="text-[#757a91] text-[15px] py-3 px-3">
                      You may choose to restrict the collection or use of your
                      personal information in the following ways:
                    </p>
                    <ul className="text-[#757a91] text-[15px] p-3 px-3">
                      <li>
                        whenever you are asked to fill in a form on the website,
                        look for the box that you can click to indicate that you
                        do not want the information to be used by anybody for
                        direct marketing purposes
                      </li>
                      <li>
                        if you have previously agreed to us using your personal
                        information for direct marketing purposes, you may
                        change your mind at any time by writing to or emailing
                        us at support@ajivainfotech.com
                      </li>
                    </ul>
                    <p className="text-[#757a91] text-[15px] py-3 px-3">
                      We will not sell, distribute or lease your personal
                      information to third parties unless we have your
                      permission or are required by law to do so. We may use
                      your personal information to send you promotional
                      information about third parties which we think you may
                      find interesting if you tell us that you wish this to
                      happen.
                    </p>
                    <p className="text-[#757a91] text-[15px] py-3 px-3">
                      If you believe that any information we are holding on you
                      is incorrect or incomplete, please write to or email us as
                      soon as possible, at the above address. We will promptly
                      correct any information found to be incorrect.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Terms_Conditions;
