import React, { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home";
import Career from "./components/Career";
import About from "./components/About";
import Contact from "./components/Contact";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import EcommerceService from "./components/EcommerceService";
import ApiIntegration from "./components/ApiIntegration";
import View_more_services from "./components/View_more_services";
import Web_Development from "./components/Web_Development";
import Website_Design from "./components/Website_Design";
import SoftwareDevelopment from "./components/SoftwareDevelopment";
import Crm_development from "./components/crm";
import Mobile_App_Development from "./components/Mobile_App_Development";
import Aservices from "./components/Aservices";
import Graphi_Design from "./components/Web & Graphic Design";
import Blog from "./components/Blog";
import Dashboard from "./components/Dashboard";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Login from "../src/container/profile/authentication/overview/SignIn";
// import Crm from "../src/components/crm";
import Case_studies from "../src/components/CaseStudies";
import Shipping_Policy from "../src/components/Shipping_Policy";
import Terms_Conditions from "../src/components/Terms&Conditions";
import Refund_Policy from "../src/components/Refund_Policy";
import Privacy_Policy from "../src/components/Privacy_Policy";
import Blog_Title from "../src/components/Blog_tittale";
import Frontend from "./components/frontend";
import NotFound from "./components/NotFound";
import Toolscomponant from "./components/tools/Toolssitemap";
import Stringify from "./components/tools/stringfy";
import Jsionchanger from "./components/tools/jsionchanger";
import Minyfiy from "./components/tools/minyfiy";
import Codedubager from "../src/components/tools/codedebuger";
import All_tools_component from "./components/allTools";
import PHP_runner from "./components/tools/PHP_runner";
import SMPT from "./components/tools/SMPT";
import HTML_and_Preview from "./components/tools/HTML_and_Preview";
import Webhook_Test from "./components/tools/Webhook_Test";
import Convert_Hex_Color_to_RGB from "./components/tools/Convert_Hex_Color_to_RGB";
import Convert_RGB_Color_to_Hex from "./components/tools/RGB_to_HEX";
import Convert_Xml_to_json from "./components/tools/Xml_to_json";
import Convert_json_to_Xml from "./components/tools/Json_to_xml";
import Base64Encode from "./components/tools/Base64Encode";
import Base64Dncode from "./components/tools/Base64Decode";
import URLEncode from "./components/tools/URLEncode";
import URLDecode from "./components/tools/URLDecode";
import Convert_PNG_to_JPG from "./components/tools/Convert_PNG_to_JPG";
import Convert_UNIX_Time_to_UTC from "./components/tools/Convert_UNIX_Time_to_UTC";
import Convert_H_M_S_to_Seconds from "./components/tools/Convert_H_to_Seconds";
import Random_Time_Generator from "./components/tools/Random_Time_Generator";
import Create_Bcrypt_Password from "./components/tools/Create_Bcrypt_Password";
import Convert_JPG_to_PNG from "./components/tools/Convert_JPG_to_PNG";
import Convert_GIF_to_PNG from "./components/tools/Convert_GIF_to_PNG";


import CssValidator from "./components/tools/Validate_CSS";

// import Login from "../src/components/login";

// const SignUp = lazy(() =>
//   import("../src/container/profile/authentication/overview/Signup")
// );
// const ForgotPass = lazy(() =>
//   import("../src/container/profile/authentication/overview/ForgotPassword")
// );
// const OneTimePass = lazy(() =>
//   import("../src/container/profile/authentication/overview/OneTimePassword")
// );
// const ResetPass = lazy(() =>
//   import("../src/container/profile/authentication/overview/ResetPassword")
// );
const ProviderConfig = () => {
  const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;
  if (window.location.host != "localhost:5000") {
    if (window.location.protocol.toLowerCase() !== "https:") {
      const url = `https://${window.location.host}`;
      window.location.replace(url);
    }
  }
 const [startTime, setStartTime] = useState(Date.now());
  const [clicks, setClicks] = useState(0);
  const [location, setLocation] = useState({ lat: null, long: null });

  useEffect(() => {
    // Track clicks on the page
    const handleDocumentClick = () => {
      setClicks(prevClicks => prevClicks + 1);
    };
    document.addEventListener('click', handleDocumentClick);

    // Get the user's geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude
          });
        },
        error => {
          console.error('Error getting geolocation:', error);
        }
      );
    }

    const handleUnload = () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000; // time in seconds

      const visitorData = {
        referrer: document.referrer,
        date: new Date(startTime).toISOString(),
        timeSpent,
        url: window.location.href,
        site: 'Your Site Name', // Replace with your actual site name
        city: 'Unknown',        // Populate this if you can determine the city
        pageUrl: window.location.pathname,
        clicks,
        lat: location.lat,
        long: location.long
      };

      // Send data to backend
      fetch(`${domainpath}/api/trackVisitor`, { // Update URL to match your server
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(visitorData)
      }).catch(error => console.error('Error sending visitor data:', error));
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [startTime, clicks, location]);

  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/career" element={<Career />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/aservices" element={<Aservices />} />
          <Route path="/case_studies" element={<Case_studies />}></Route>
          <Route path="/shipping&policy" element={<Shipping_Policy />}></Route>
          <Route path="/refund&policy" element={<Refund_Policy />}></Route>
          <Route path="/privacy&policy" element={<Privacy_Policy />}></Route>
          <Route
            path="/terms&conditions"
            element={<Terms_Conditions />}
          ></Route>
          {/* ==============================================THIS IS TOOLS ROUTE==================================================== */}
          <Route path="/tools/codedubager" element={<Codedubager />}></Route>
          <Route path="/tools/Minyfiy" element={<Minyfiy />}></Route>
          <Route path="/tools/" element={<All_tools_component />}></Route>
          <Route path="/tools/sitemaps" element={<Toolscomponant />}></Route>
          <Route path="/tools/strreplace" element={<Stringify />}></Route>
          <Route path="/tools/jsonvalidator" element={<Jsionchanger />}></Route>
          <Route path="/tools/php" element={<PHP_runner />}></Route>
          <Route path="/tools/html" element={<HTML_and_Preview />}></Route>
          <Route path="/tools/webhook" element={<Webhook_Test />}></Route>
          <Route path="/tools/smpt" element={<SMPT />}></Route>
          <Route path="/tools/base64-encode" element={<Base64Encode />}></Route>
          <Route path="/tools/base64-decode" element={<Base64Dncode />}></Route>
          <Route path="/tools/url-encode" element={<URLEncode />}></Route>
          <Route path="/tools/url-decode" element={<URLDecode />}></Route>

          <Route
            path="/tools/png-to-jpg"
            element={<Convert_PNG_to_JPG />}
          ></Route>

          <Route
            path="/tools/xml-to-json"
            element={<Convert_Xml_to_json />}
          ></Route>
          <Route
            path="/tools/xml-minify"
            element={<Convert_json_to_Xml />}
          ></Route>

          <Route
            path="/tools/rgb-to-hex"
            element={<Convert_RGB_Color_to_Hex />}
          ></Route>
          <Route
            path="/tools/hex-to-rgb"
            element={<Convert_Hex_Color_to_RGB />}
          ></Route>
          {/* ================28-5========================== */}
          <Route
            path="/tools/utc-to-unix"
            element={<Convert_UNIX_Time_to_UTC />}
          ></Route>
          <Route
            path="/tools/hms-to-seconds"
            element={<Convert_H_M_S_to_Seconds />}
          ></Route>
          <Route
            path="/tools/random-time"
            element={<Random_Time_Generator />}
          ></Route>
          <Route
            path="/tools/bcrypt"
            element={<Create_Bcrypt_Password />}
          ></Route>
          {/* ===========29-5================= */}
          <Route
            path="/tools/jpg-to-png"
            element={<Convert_JPG_to_PNG />}
          ></Route>
          <Route
            path="/tools/gif-to-png"
            element={<Convert_GIF_to_PNG />}
          ></Route>
          <Route path="/tools/css-validate" element={<CssValidator />}></Route>
          {/* ==============================================THIS IS TOOLS ROUTE END ==================================================== */}
          <Route
            path="services/view_more_services"
            element={<View_more_services />}
          />
          <Route
            path="/services/mobile-app-development"
            element={<Mobile_App_Development />}
          />
          <Route
            path="/services/crm-development"
            element={<Crm_development />}
          />
          <Route path="/services/frontend-development" element={<Frontend />} />
          <Route path="/blog/:id" element={<Blog_Title />} />
          <Route
            path="/services/web-development"
            element={<Web_Development />}
          />
          <Route
            path="/services/ecommerce-development"
            element={<EcommerceService />}
          />
          <Route
            path="/services/api-integration"
            element={<ApiIntegration />}
          />
          <Route
            path="/services/graphic-design"
            element={<Graphi_Design />}
          />
          <Route
            path="/services/website-design"
            element={<Website_Design />}
          />
          <Route
            path="/services/software-development"
            element={<SoftwareDevelopment />}
          />
          
          {/* <Route path="/services/Crm" element={<Crm />} /> */}

          {/* <Route path="/service/ecommerce-development" element={<EcommerceService />} /> */}
          {/* <Route path="/service/ecommerce-development" element={<EcommerceService />} /> */}
          {/* <Route exact path="/forgotPassword" component={ForgotPass} /> */}
          {/* <Route exact path="/oneTimePassword" component={OneTimePass} /> */}
          {/* <Route exact path="/resetPassword/:id" component={ResetPass} /> */}
          {/* <Route exact path="/register" component={SignUp} /> */}

          <Route exact path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
};
export default ProviderConfig;
