import React, { useEffect, useState } from "react";
import {
  NavLink,
  NavNavLink,
  useParams,
  BrowserRouter,
} from "react-router-dom";
import { headers } from "../helpers/variables";
import pdf from "../img/Pdf.webp";
import lets from "../img/Lats-talk.webp";
import needhelp from "../img/Need-help.webp";
import help from "../img/Need-help.webp";
import {
  get_api_request,
  post_api_request,
  put_api_request,
  delete_api_request,
  api_url,
} from "../helpers/Api.js";

function Aservices() {
  const [Web_Development, setWeb_Development] = useState();
  const [Web_Design_Graphic_Design, setWeb_Design_Graphic_Design] = useState();
  const [RequirementClass, setRequirementClass] = useState(true);
  const [PlaningClass, setPlaningClass] = useState(false);
  const [DesignClass, setDesignClass] = useState(false);
  const [DevelopmentClass, setDevelopmentClass] = useState(false);
  const [TestingClass, setTestingClass] = useState(false);
  const [LaunchClass, setLaunchClass] = useState(false);
  const [MaintenanceClass, setMaintenanceClass] = useState(false);
  const [h1Text, setH1Text] = useState("Requirement Gathering");
  const [pText, setPText] = useState(
    "The process of gathering requirements goes beyond asking a few questions and moving on. All projects should follow some steps to gather requirements. Working through the process takes time, whether it is a small or substantial project. Make sure you ask the right questions and listen carefully to the answers. The particular requirement can be collected using a variety of techniques. Interviews, facilitated sessions, prototypes, and questionnaires are all part of the process.Our next step is to validate the information collected and confirm that it reflects the client’s ideology. Creating models that visualize the process involves consolidating requirements. The development life cycle is tracked through documents and reports. Provide all the required information to the client for approval after gathering all the required information."
  );

  const handleChangeData = (e) => {
    if (e == "Requirement") {
      setH1Text("Requirement Gathering");
      setRequirementClass(true);
      setPlaningClass(false);
      setDesignClass(false);
      setDevelopmentClass(false);
      setTestingClass(false);
      setLaunchClass(false);
      setMaintenanceClass(false);
      setPText(
        "The process of gathering requirements goes beyond asking a few questions and moving on. All projects should follow some steps to gather requirements. Working through the process takes time, whether it is a small or substantial project. Make sure you ask the right questions and listen carefully to the answers. The particular requirement can be collected using a variety of techniques. Interviews, facilitated sessions, prototypes, and questionnaires are all part of the process.Our next step is to validate the information collected and confirm that it reflects the client’s ideology. Creating models that visualize the process involves consolidating requirements. The development life cycle is tracked through documents and reports. Provide all the required information to the client for approval after gathering all the required information."
      );
    } else if (e == "planing_process") {
      setH1Text("Planing Process");
      setRequirementClass(false);
      setPlaningClass(true);
      setDesignClass(false);
      setDevelopmentClass(false);
      setTestingClass(false);
      setLaunchClass(false);
      setMaintenanceClass(false);
      setPText(
        "The journey begins with a deep dive into your vision, goals, and target audience. Through detailed consultations and thorough analysis, we gain a comprehensive understanding of your business objectives and unique requirements. We work closely with you to develop a strategic plan that outlines the roadmap for your website project. This includes defining key milestones, establishing project timelines, and identifying the resources needed for success. Our goal during this phase is to ensure that every aspect of the project is aligned with your business objectives and user expectations"
      );
    } else if (e == "Design_process") {
      setH1Text("Design process");
      setDesignClass(true);
      setRequirementClass(false);
      setPlaningClass(false);
      setDevelopmentClass(false);
      setTestingClass(false);
      setLaunchClass(false);
      setMaintenanceClass(false);
      setPText(
        "With a clear plan in place, our expert design team brings your vision to life through stunning visuals and intuitive interfaces. Leveraging the latest design trends and technologies, we create mockups and prototypes that reflect your brand identity and resonate with your target audience. Throughout the design process, we prioritize collaboration and feedback, ensuring that the final design exceeds your expectations while prioritizing user experience and accessibility."
      );
    } else if (e == "Development_update") {
      setH1Text("Development update");
      setDevelopmentClass(true);
      setDesignClass(false);
      setRequirementClass(false);
      setPlaningClass(false);
      setTestingClass(false);
      setLaunchClass(false);
      setMaintenanceClass(false);
      setPText(
        "Once the design is approved, our skilled development team gets to work turning concepts into reality. We follow industry best practices and agile methodologies to deliver high-quality code that is scalable, secure, and optimized for performance. Throughout the development process, we provide regular updates and transparent communication, keeping you informed of our progress every step of the way. Our goal is to ensure that your website not only meets but exceeds your expectations, delivering a seamless and engaging user experience."
      );
    } else if (e == "Testing") {
      setH1Text("Testing");
      setTestingClass(true);
      setDevelopmentClass(false);
      setDesignClass(false);
      setRequirementClass(false);
      setPlaningClass(false);
      setLaunchClass(false);
      setMaintenanceClass(false);
      setPText(
        "Quality assurance is paramount to us. Before your website goes live, we conduct rigorous testing across multiple devices, browsers, and user scenarios to identify and address any issues or bugs. Our comprehensive testing process ensures that your website is fully functional, error-free, and ready to provide a seamless experience to your users from day one. We leave no stone unturned in our quest for perfection, ensuring that your website is of the highest quality before it goes live."
      );
    } else if (e == "Launch") {
      setH1Text("Launch");
      setLaunchClass(true);
      setTestingClass(false);
      setDevelopmentClass(false);
      setDesignClass(false);
      setRequirementClass(false);
      setPlaningClass(false);
      setMaintenanceClass(false);
      setPText(
        "With thorough planning and meticulous preparation, we execute a smooth and successful website launch. Our experienced team handles all aspects of deployment, including domain setup, hosting configuration, and content migration, to ensure a seamless transition from development to the live environment. We work tirelessly to ensure that your website goes live on time and on budget, ready to make a lasting impression on your audience."
      );
    } else if (e == "Maintenance") {
      setH1Text("Maintenance");
      setMaintenanceClass(true);
      setLaunchClass(false);
      setTestingClass(false);
      setDevelopmentClass(false);
      setDesignClass(false);
      setRequirementClass(false);
      setPlaningClass(false);
      setPText(
        "Our commitment to your success doesn't end at launch. We offer ongoing maintenance and support services to keep your website running smoothly and securely. From regular updates and security patches to content management and performance monitoring, we're here to ensure that your website continues to meet your evolving needs and exceed your expectations. With AjivaInfoch Pvt Ltd, you can trust that your website is in capable hands every step of the way, from conception to maintenance."
      );
    }
  };

  return (
    <>
      <div className="cocontainer m-auton">
        <section>
          <div className="container m-auto lg:my-8">
            <div className="px-20 mx-20">
              <div>
                <div className="Phases">
                  <h2 className="text-[#333333]">
                    Phases Of The Website Design and Development Process
                  </h2>
                </div>
                <div className="pt-5">
                  <p className="this text-[#515151] text-base font-medium">
                    We produce visually stunning and engaging websites that
                    communicate the values of our clients. Online sales<br></br>{" "}
                    and lead generation are two of the ways these websites
                    assist their businesses in growing.
                  </p>
                </div>
              </div>
              <div className="dret">
                <div className="fee">
                  <div>
                    <div className=" flex fees">
                      <div
                        className={
                          RequirementClass == true ? "iconf active" : "iconf"
                        }
                        onClick={(e) => handleChangeData("Requirement")}
                      >
                        <div className="cricle">
                          <i
                            class="fa-solid fa-folder-tree  text-3xl"
                            fa-solid
                            fa-object-group="true"
                          ></i>
                        </div>
                        <div className="d2">
                          <span className="text-[#515151] text-base font-medium">
                            Requirement Gathering
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          RequirementClass == true
                            ? "arrows arrowActive"
                            : "arrows"
                        }
                      ></div>
                      <div
                        className={
                          PlaningClass == true ? "iconf active" : "iconf"
                        }
                        onClick={(e) => handleChangeData("planing_process")}
                      >
                        <div className="cricleplaning cricle">
                          <i
                            class="fa-solid fa-file-signature text-3xl"
                            fa-solid
                            fa-object-group="true"
                          ></i>
                        </div>
                        <div className="d2">
                          <span className="text-[#515151] text-base font-medium">
                            {" "}
                            Planing Process
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          PlaningClass == true ? "arrows arrowActive" : "arrows"
                        }
                      ></div>
                      <div
                        className={
                          DesignClass == true ? "iconf active" : "iconf"
                        }
                        onClick={(e) => handleChangeData("Design_process")}
                      >
                        <div className="cricledesign cricle">
                          <i
                            class="fa-solid fa-object-group text-3xl"
                            fa-solid
                            fa-object-group="true"
                          ></i>
                        </div>
                        <div className="d2">
                          <span className="text-[#515151] text-base font-medium">
                            Design process{" "}
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          DesignClass == true ? "arrows arrowActive" : "arrows"
                        }
                      ></div>
                      <div
                        className={
                          DevelopmentClass == true ? "iconf active" : "iconf"
                        }
                        onClick={(e) => handleChangeData("Development_update")}
                      >
                        <div className=" criclesdevp cricle">
                          <i
                            class="fa-solid fa-laptop-code text-3xl"
                            fa-solid
                            fa-object-group="true"
                          ></i>
                        </div>
                        <div className="d2">
                          <span className="text-[#515151] text-base font-medium">
                            Development update{" "}
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          DevelopmentClass == true
                            ? "arrows arrowActive"
                            : "arrows"
                        }
                      ></div>
                      <div
                        className={
                          TestingClass == true ? "iconf active" : "iconf"
                        }
                        onClick={(e) => handleChangeData("Testing")}
                      >
                        <div className="criclePHP cricle">
                          <i
                            class="fa-solid fa-list-check text-3xl"
                            fa-solid
                            fa-object-group="true"
                          ></i>
                        </div>
                        <div className="d2">
                          <span className="text-[#515151] text-base font-medium">
                            Testing{" "}
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          TestingClass == true ? "arrows arrowActive" : "arrows"
                        }
                      ></div>
                      <div
                        className={
                          LaunchClass == true ? "iconf active" : "iconf"
                        }
                        onClick={(e) => handleChangeData("Launch")}
                      >
                        <div className="cricle8 cricle">
                          <i
                            class="fa-solid fa-rocket text-3xl"
                            fa-solid
                            fa-object-group="true"
                          ></i>
                        </div>
                        <div className="d2">
                          <span className="text-[#515151] text-base font-medium">
                            Launch{" "}
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          LaunchClass == true ? "arrows arrowActive" : "arrows"
                        }
                      ></div>
                      <div
                        className={
                          MaintenanceClass == true ? "iconf active" : "iconf"
                        }
                        onClick={(e) => handleChangeData("Maintenance")}
                      >
                        <div className="cricleMaintenance cricle">
                          <i
                            class=" fa-solid fa-toolbox text-3xl"
                            fa-solid
                            fa-object-group="true"
                          ></i>
                        </div>
                        <div className="d2">
                          <span className="text-[#515151] text-base font-medium">
                            Maintenance{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-red-600	">
                <div>
                  <div className=" process px-10  my-10 tt">
                    <div className="text-2xl font-semibold pl-5 ">
                      <h1 className="my-7 REQ text-2xl font-bold text-[#333333]">
                        {h1Text}
                      </h1>
                    </div>
                    <div className="pl-5  ">
                      <p className="element pb-10 mb-10 text-[#515151] text-base font-medium">
                        {pText}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-[#f6fafd] lg:py-8 lg:my-8 lg:px-12 px-3 lg:my-5">
          <div className="container mx-auto lg:mb-[20px]">
            <div>
              {" "}
              <div className="box-frist-121"></div>
              <div className="w-full lg:flex our-purpose designsolutions lg:p-5 ju">
                <div className="lg:w-1/2">
                  <div className="mx-15 cd shedow">
                    <div className="lg:px-12 lg:py-4 cop">
                      <h2 className="  lg:py-4 web ">Web Design</h2>
                      <p className="text-base text-[#515151] pb-3 font-medium text-sm	">
                        We create unique hand-crafted website design solutions
                        that build your online presence to its full potential.
                        We like to be as creative and innovative as our clients
                        allow us to be, designing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2">
                  <div className="mx-15 lg:pt-12 lg:justify-center flex">
                    <div className="FIND_OUT">
                      <div className="p-3 border-[1px] border-[#828282] rounded-[10px] ">
                        <h2 className="text-xl font-bold  ">FIND OUT MORE</h2>
                        <p className="text-base text-[#515151] pb-3 text-sm	  font-medium">
                          To learn more about our web design services and how we
                          can help your business, get in touch.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="gettouchbtn">
                    <div className="group relative inline-block overflow-hidden border border-gray-600 px-8 py-3 focus:outline-none focus:ring  aj_btn_get_start">
                      <span className="absolute inset-y-0 left-0 w-[0px] bg-red-600 transition-all group-hover:w-full group-active:bg-red-600"></span>

                      <p className="relative text-sm font-medium text-[#181818] transition-colors group-hover:text-white text-base">
                        Get In Touch
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-seciond-121"></div>
            </div>

            <div className="w-full lg:flex">
              <div className="lg:w-4/12 lg:border-r border-b border-[#212529]">
                <div className=" mx-15 cd " id="Web_Design">
                  <div className="px-12 lg:py-4 ">
                    <h2 className="text-xl font-bold  lg:mt-0 lg:py-5 text-[#333333] text_font_wait">
                      Ecommerce Development
                    </h2>
                    <p className="text-base text-[#515151] pb-3 font-medium text-sm	">
                      To learn more about our Ecommerce Development and how we
                      can help your business, get in touch.
                    </p>
                    <div className="flex">
                      <a
                        href="/services/ecommerce-development"
                        className="p-1 text-red-700"
                      >
                        Read more
                      </a>
                      <div>
                        <a
                          href="/services/ecommerce-development"
                          className="text-black-600 text-1xl font-semibold "
                        />

                        <i class=" fa-solid fa-arrow-right-long pt-[10px] text-red-700 atag" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-4/12 lg:border-r border-b border-[#212529]">
                <div className=" mx-15 cd " id="Web Devlo">
                  <div className="px-12 py-4">
                    <h2 className="lg:text-xl font-bold py-5 text-[#333333] text_font_wait">
                      Web Development
                    </h2>
                    <p className="text-base text-[#515151] pb-3 font-medium text-sm	">
                      To learn more about our Web Development and how we can
                      help your business, get in touch.
                    </p>
                    <div className="flex">
                      <a
                        href="/services/web-development"
                        className="p-1 text-red-700"
                      >
                        Read more
                      </a>
                      <div>
                        <a
                          href="/services/web-development"
                          className="text-black-600 text-1xl font-semibold "
                        />

                        <i class="fa-solid fa-arrow-right-long pt-[10px] text-red-700  atag" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-4/12  border-b border-[#212529]">
                <div className=" mx-15 cd ">
                  <div className="px-12 py-4">
                    <h2 className="lg:text-xl font-bold py-5 text-[#333333] text_font_wait">
                      Web Design &Graphic Design
                    </h2>
                    <p className="text-base text-[#515151] pb-3 font-medium text-sm	">
                      To learn more about our web design services and how we can
                      help your business, get in touch.
                    </p>
                    <div className="flex">
                      <a href="graphic-design" className="p-1 text-red-700">
                        Read more
                      </a>
                      <div>
                        <a
                          href="graphic-design"
                          className="text-black-600 text-1xl font-semibold "
                        >
                          <i class="fa-solid fa-arrow-right-long pt-[10px] text-red-700 atag" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:flex">
              <div className="lg:w-4/12 lg:border-r lg:border-b-0 border-b border-[#212529]">
                <div className=" mx-15 cd ">
                  <div className="px-12 py-4">
                    <h2 className="lg:text-xl font-bold py-5 text-[#333333] text_font_wait">
                      API Interation
                    </h2>
                    <p className="text-base text-[#515151] pb-3 font-medium text-sm	">
                      To learn more about our API Interation and how we can help
                      your business, get in touch.
                    </p>
                    <div className="flex">
                      <a
                        href="/services/api-integration"
                        className="p-1 text-red-700 "
                      >
                        Read more
                      </a>
                      <div>
                        <a
                          href="/services/api-integration"
                          className="text-black-600 text-1xl font-semibold "
                        >
                          <i className="fa-solid fa-arrow-right-long pt-[10px] text-red-700 atag" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-4/12 lg:border-r lg:border-b-0 border-b border-[#212529]">
                <div className=" mx-15 cd ">
                  <div className="px-12 py-4">
                    <h2 className="lg:text-xl font-bold py-5 text-[#333333] text_font_wait">
                      Mobile App Development
                    </h2>
                    <p className="text-base text-[#515151] pb-3 font-medium text-sm	">
                      To learn more about our Mobile App Development and how we
                      can help your business, get in touch.
                    </p>
                    <div className="flex">
                      <a
                        href="/services/mobile-app-development"
                        className="p-1 text-red-600"
                      >
                        Read more
                      </a>
                      <div>
                        <a
                          href="/services/mobile-app-development"
                          className="text-black-600 text-1xl font-semibold "
                        />
                        <i className="fa-solid fa-arrow-right-long pt-[10px] text-red-700 atag" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-4/12 ">
                <div className=" mx-15 cd ">
                  <div className="px-12 py-4">
                    <h2 className="lg:text-xl font-bold py-5 text-[#333333] text_font_wait">
                      CRM Development
                    </h2>
                    <p className="text-base text-[#515151] pb-3 font-medium text-sm	">
                      To learn more about our web design services and how we can
                      help your business, get in touch.
                    </p>
                    <div className="flex ">
                      <a
                        href="/services/crm-development"
                        className="p-1 text-red-600"
                      >
                        Read more
                      </a>
                      <div>
                        <a
                          href="/services/crm-development"
                          className="text-black-600 text-1xl font-semibold "
                        />
                        <i className="fa-solid fa-arrow-right-long pt-[10px] text-red-700 atag" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Aservices;
